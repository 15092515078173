<template>
  <div id="app">
    <TranslateText />
  </div>
</template>

<script>
import TranslateText from './TranslateText.vue'; // 更新引用

export default {
  name: 'App',
  components: {
    TranslateText // 更新组件注册
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
