<template>
<div class="app-container flex flex-col items-center mx-auto mt-5 font-sans">
    <div class="content flex flex-col md:flex-row justify-center items-start bg-white p-5 shadow-md rounded-lg w-full md:w-11/12 lg:w-10/12">
      <textarea v-model="inputText"
                @input="handleInput"
                placeholder="{'Unix_timestamp': 1717062120, 'unicode': u'\u5e78\u8fd0', 'str': 'UTF-8 \xe7\xbc\x96\xe7\xa0\x81\xe7\x9a\x84 \xe5\x8d\x81\xe5\x85\xad\xe8\xbf\x9b\xe5\x88\xb6'}"
                class="input-text flex-1 min-h-[15rem] md:mr-2 border border-gray-300 rounded p-3 resize-none w-full"
                style="height: 5em;">
      </textarea>
      <div v-if="outputText || true"
           ref="output"
           class="output-text flex-1 min-h-[15rem] md:ml-2 border border-gray-200 bg-gray-100 rounded p-3 overflow-auto w-full"
           v-html="outputText || '{&lt;br&gt;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;<span style=&quot;color: #0531bc;&quot;>&quot;Unix_timestamp&quot;: </span>&quot;2024-05-30 17:42:00&quot;,<br>&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;<span style=&quot;color: #8ab3b1;&quot;>&quot;unicode&quot;: </span>&quot;幸运&quot;,<br>&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;<span style=&quot;color: #341be9;&quot;>&quot;str&quot;: </span>&quot;UTF-8 编码的 十六进制&quot;&lt;br&gt;}'"
           style="white-space: pre-wrap; text-align: left;">
      </div>
    </div>
</div>
<h2 id="app-ad-heading" class="mb-0 pt-12 text-center text-5xl font-normal text-black" style="margin-top: 2rem;">EasyRead Tools</h2>

<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; height: 30vh;">
  <span class="text-xl font-normal" style="margin-bottom: 1rem;">Formatter Beautifier and Viewer All in One</span>
  <p class="text-base font-normal" style="margin-bottom: 1rem;">处理和查看各种数据格式，让复杂的数据展现变得简单易读</p>
  <p class="text-base font-normal" style="margin-bottom: 1rem;">对包括字符串、Unicode、URL编码、时间戳在内的多种数据进行解码</p>
  <p class="text-base font-normal" style="margin-bottom: 1rem;">解析JSON字符串进行格式化展示，带参数高亮，支持不完整的JSON</p>
</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'TranslateText',
  data() {
    return {
      inputText: '',
      outputText: ''
    };
  },
  methods: {
    adjustHeight(event, type) {
      const target = type === 'input' ? event.target : this.$refs.output;
      target.style.height = 'auto'; // Reset height to recalibrate
      target.style.height = `${target.scrollHeight}px`; // Set new height based on content
    },
    async translateText() {
      if (!this.inputText) return;
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // 获取本地时区
      try {
        const response = await axios.post('https://easyread.tools/api/decode', {
          data: this.inputText
        }, {
          headers: {
            'Content-Type': 'application/json',
            'X-Time-Zone': timeZone // 将时区作为header发送
          }
        });
        this.outputText = response.data.translated;
        this.$nextTick(() => {
          this.adjustHeight(null, 'output'); // Adjust output height after updating outputText
        });
      } catch (error) {
        console.error('Error translating:', error);
        this.outputText = '翻译时发生错误';
        this.$nextTick(() => {
          this.adjustHeight(null, 'output'); // Adjust output height even on error
        });
      }
    },
    handleInput(event) {
      this.adjustHeight(event, 'input');
      this.translateText(); // Automatically translate text when input changes
    }
  }
};
</script>

<style>
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .input-text, .output-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 100%; /* Ensure full width */
  }
}
</style>
